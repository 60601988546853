<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>銷售應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>{{ isBranchProductList ? '分店商品列表' : '商品管理' }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
            {{ isBranchProductList ? '分店商品列表' : '商品管理' }}
          </h4>
          <div
            class="
              col-12 col-xl-10
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-form-select
              id="branch-select"
              v-if="isBranchProductList"
              v-model="selectedBranch"
              :options="branchOptions"
              @change="handleBranchChange"
              class="mr-2 mb-2 mb-xl-0"
            >
              <template #first>
                <b-form-select-option :value="null">請選擇</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              v-if="!isBranchProductList && checkPermission([consts.BRANCH_PRODUCT_SYNC])"
              @click="handleSyncAllBranchProduct"
              variant="primary"
            ><i class="mdi mdi-sync" aria-hidden="true"></i>同步所有商品至分店
            </b-button>
            <!-- <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              variant="primary"
              :to="{
                name: 'ProductCreate',
                query: { merchant_id: this.query.merchant_id },
              }"
              :disabled="isBranchProductList"
              ><i class="fa fa-plus" aria-hidden="true"></i>新增商品
            </b-button> -->
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              v-if="checkPermission([consts.PRODUCT_IMPORT])"
              variant="primary"
              v-b-modal.modal-upload
            ><i class="fa fa-upload" aria-hidden="true"></i>匯入商品
            </b-button>
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              v-if="checkPermission([consts.PRODUCT_CREATE])"
              variant="primary"
              :to="{
                name: 'ProductCreateV2',
                query: { merchant_id: this.query.merchant_id },
              }"
              :disabled="isBranchProductList"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增商品
            </b-button>
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              variant="primary"
              v-if="isBranchProductList"
              @click="handleSyncProductFromMain"
            > <i class="fa fa-rotate-right" aria-hidden="true"></i>同步商品
            </b-button>
            <b-form-select
              v-model="query.type"
              :options="productTypes"
              class="ml-0 ml-xl-2"
              @change="handlePageReset"
            >
              <template #first>
                <b-form-select-option :value="null">全部類型</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="ml-0 ml-xl-2"
              v-model="query.merchant_id"
              :options="merchants"
              :disabled="merchants.length == 2"
              @change="handlePageReset"
            ></b-form-select>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋商品"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="products" :fields="fields">
          <template #cell(name)="data">
            <router-link
              :to="{
                name: 'ProductDetailV2',
                params: {
                  product_id: data.item.id,
                },
              }"
            >
              <div class="mb-1">{{ data.item.title }}</div>
              <div>{{ data.item.sale_page_code }}</div>
            </router-link>
          </template>
          <template #cell(picture)="data">
            <b-img-lazy
              v-if="data.item.images[0] && data.item.images[0].pic_url"
              class="thumb-image"
              :src="data.item.images[0] && data.item.images[0].pic_url"
              alt="商品圖片"
              width="50"
              height="50"
            />
          </template>
          <template #cell(started_at_and_end_at)="data">
            <div class="mb-1">
              {{ formatDate(data.item.available_start_at) }}
            </div>
            <div>{{ formatDate(data.item.available_end_at) }}</div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(type)="data">
            {{ getProductTypeText(data.item.type) }}
          </template>
          <template #cell(arrange)="data">

            <!-- <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.PRODUCT_LIST_VIEW])"
              :to="{
                name: 'ProductDetail',
                params: {
                  product_id: data.item.id,
                },
              }"
              ><span class="mdi mdi-eye mr-1"></span>查看
            </b-button> -->
            <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.PRODUCT_LIST_VIEW])"
              :to="{
                name: 'ProductDetailV2',
                params: {
                  product_id: data.item.id,
                },
              }"
            ><span class="mdi mdi-eye mr-1"></span>查看
            </b-button>
            <!-- <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="isBranchProductList ? checkPermission([consts.BRANCH_PRODUCT_MODIFY]) : checkPermission([consts.PRODUCT_MODIFY])"
              :to="{
                name: 'ProductEdit',
                params: {
                  product_id: data.item.id,
                },
              }"
              ><span class="mdi mdi-lead-pencil mr-1"></span>編輯
            </b-button> -->
            <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="isBranchProductList ? checkPermission([consts.BRANCH_PRODUCT_MODIFY]) : checkPermission([consts.PRODUCT_MODIFY])"
              :to="{
                name: 'ProductEditV2',
                params: {
                  product_id: data.item.id,
                },
              }"
            ><span class="mdi mdi-lead-pencil mr-1"></span>編輯
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-danger"
              @click="handleDelete(data.item.id)"
              v-if="isBranchProductList ? checkPermission([consts.BRANCH_PRODUCT_DELETE]) : checkPermission([consts.PRODUCT_DELETE])"
              ><span class="mdi mdi-delete mr-1"></span>刪除
            </b-button>
            <b-dropdown variant="inverse-secondary" class="ml-2">
              <template slot="button-content">
                <i class="mdi mdi-menu"></i>
              </template>
              <b-dropdown-item
                class="ml-2"
                v-if="checkPermission([consts.PRODUCT_IMAGE_MODIFY])"
                :to="{
                name: 'ProductUpdateImage',
                params: {
                  product_id: data.item.id,
                },
              }"
              ><i class="mdi mdi-image-multiple"></i>設定圖片
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!isBranchProductList && checkPermission([consts.BRANCH_PRODUCT_SYNC])"
                @click="handleSyncOneProductToBranch(data.item.id)"
              ><i class="mdi mdi-sync" aria-hidden="true"></i>同步至所有分店
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
    <b-modal
      id="modal-upload"
      title="批次上傳"
    >
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">
            請上傳帳號 Excel 檔案
          </label>
          <b-form-file plain @input="uploadProduct" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="
            getSampleExcelFile(
              null,
              '/excel/product_list.xlsx'
            )
          "
          ><i class="fa fa-file-text"></i
          >下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import merchantApi from "../../../apis/merchant";
import productApi from "../../../apis/product";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import {paginationMixin} from "@/mixins/pagination";
import { getSampleExcelFile } from "@/utils/dashboardUtils";

export default {
  mixins: [paginationMixin],
  components: { CustomPagination },
  data() {
    return {
      consts,
      showLoading: false,
      showLoadingUpload: false,
      merchants: [{ value: null, text: "請選擇" }],
      totalRows: 0,
      fields: [
        {
          key: "name",
          label: "商品名稱/編號",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "price",
          label: "售價/原價",
        },
        {
          key: "started_at_and_end_at",
          label: "開始/結束販售時間",
        },
        {
          key: "status",
          label: "商品狀態",
        },
        {
          key: "branch",
          label: "分店名稱/ID",
        },
        {
          key: "is_closed",
          label: "是否關閉",
        },
        {
          key: "type",
          label: "商品類型",
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      products: [],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        merchant_id: null,
        branch_id: null,
        type: null,
      },
      productTypes: [],
      // https://github.com/Waltily-Inc/waltily-frontend/issues/3473
      // 「分店商品列表」 與 「一般商品列表」 共用 ProductList.vue
      // 因此需要一個變數來判斷是否為「分店商品列表」
      isBranchProductList: false, // 是否為「分店商品列表」
      selectedBranch: null,
    };
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = { type: null };
      this.query = { ...defaultQuery, ...to.query };
      this.fetchProducts();
    },
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapState("general", {
      defaultMerchantType: (state) => state.defaultMerchantType,
      myBranches: state => state.myBranches,
      currentBranch: state => state.currentBranch,
    }),
    ...mapGetters("general", ["branchOptions"]),
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.isBranchProductList = this.$route.name === 'BranchProductList';
    if (this.isBranchProductList) {
      this.selectedBranch = this.query.branch_id;
    }
  },
  async mounted() {
    await this.fetchMerchants();
    await this.fetchProductTypes();
    this.initialized = true;
  },
  methods: {
    getSampleExcelFile,
    async uploadProduct(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("merchant_id", this.query.merchant_id);
      formData.append("branch_id", this.query.branch_id || null);
      try {
        let response = await productApi.uploadProduct(formData);

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.fetchProducts()
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    async fetchProducts() {
      if (!this.query.merchant_id) return;

      // 在「分店商品列表」中，若未選擇分店，則不進行查詢
      if (this.isBranchProductList && !this.query.branch_id) return;

      // 多下一層保護，避免在「商品管理」中，因為左側的分店下拉選單更新導致 branch_id 被帶入特定值查詢
      if (!this.isBranchProductList) {
        this.query.branch_id = null;
      }
      this.showLoading = true;

      try {
        const params = {
          ...this.query,
        };

        let response = await productApi.getProducts(params);

        const { data } = response;

        this.products = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        alert("取得商品資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant = this.findDefaultMerchant(data.data);

      if (defaultMerchant) {
        this.query.merchant_id = defaultMerchant.id;
        await this.fetchProducts();
      }
    },
    findDefaultMerchant(merchants) {
      let defaultMerchant;

      if (this.$route.query.merchant_id) {
        defaultMerchant = merchants.find((merchant) => {
          return merchant.id === this.$route.query.merchant_id;
        });

        if (!defaultMerchant) {
          this.$swal.fire({
            type: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
      } else if (merchants.length == 1) {
        defaultMerchant = merchants[0];
      } else {
        defaultMerchant =
          merchants.find(
            (merchant) => merchant.type === this.defaultMerchantType
          ) ?? merchants.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE);
      }

      return defaultMerchant;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await productApi.deleteProduct(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    async handleSyncAllBranchProduct() {
      if (this.isBranchProductList) return;
       this.$swal
         .fire({
           type: "warning",
           title: "你確定要同步所有分店商品嗎？",
           confirmButtonText: "確定",
           cancelButtonText: "取消",
           showCancelButton: true,
           reverseButtons: true,
         })
         .then(async (result) => {
           if (result.value) {
             try {
               const response = await productApi.syncBranchProducts();
               if (response.status === 200) {
                 await this.$swal.fire({
                   title: "成功",
                   type: "success",
                   text: "同步已開始進行",
                 });
                 await this.fetchProducts();
               }
             } catch (error) {
               this.$swal("失敗", error.response.data.message, "同步觸發失敗。同步可能正在進行中，請稍候再試");
             }
           }
         });
    },
    async handleSyncOneProductToBranch(productId) {
      if (this.isBranchProductList) return;
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要同步此商品至所有分店嗎？",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          showCancelButton: true,
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await productApi.syncBranchProducts({ product_id: productId });
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "同步已開始進行",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "同步觸發失敗。同步可能正在進行中，請稍候再試");
            }
          }
        });
    },
    async handleSyncProductFromMain() {
      if (!this.isBranchProductList) return;
      if (!this.selectedBranch) {
        this.$swal.fire({
          type: "warning",
          title: "請選擇分店",
          confirmButtonText: "確定",
        });
        return;
      }
      const branch = this.branchOptions.find((branch) => branch.value === this.selectedBranch);
      console.log(branch)
      this.$swal
        .fire({
          type: "warning",
          title: "確定將商品同步一份至" + branch.text + "嗎？",
          confirmButtonText: "確定同步",
          cancelButtonText: "取消",
          showCancelButton: true,
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await productApi.syncBranchProducts({'branch_id': branch.value});
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "同步已開始進行",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "同步觸發失敗。同步可能正在進行中，請稍候再試");
            }
          }
        });
    },
    async fetchProductTypes() {
      try {
        const { data } = await productApi.getProductTypes();
        this.productTypes = data.data.map(type => ({ value: type.value, text: type.text }));
      } catch (error) {
        console.error(error);
        alert("取得商品分類錯誤");
      }
    },
    getProductTypeText(type) {
      const productType = this.productTypes.find(pt => pt.value === type);
      return productType ? productType.text : type;
    },
    ...mapActions("general", ["setCurrentBranch"]),

    handleBranchChange() {
      this.query.branch_id = this.selectedBranch;
      this.handlePageReset();
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
